import HttpService from "@common/services/http";
import JwtService from "@common/services/jwt";
import MessageService from "@common/services/message";
import NavigatorService from "@common/services/navigator";
import Networking from "@common/services/networking";
import MemoryStorageService from "@universal/services/memoryStorage";
import UrlService from "@common/services/url";
import Application from "@universal/lib/application";
import ApiService from "@universal/services/api";
import ConfigurationService from "@universal/services/configuration";
import RepositoryService from "@universal/services/repository";
import SessionService from "@universal/services/session";
import ModelLoaders               from "@uBusiness/model";
import CurrentTenantService from "@universal/services/currentTenant";
import UserStorageStrategyService from "@universal/services/userStorageStrategy";
import AclService from "@universal/services/acl";
import I18nService from "@universal/services/i18n";

export type StartHandler = (application: Application) => Promise<void>;

const createApplication = async (configuration: ConfigurationService, beforeStart: StartHandler = async () => {}, afterStart: StartHandler = async () => {}) => {
  const application = new Application();
  application.initializeServices([
    configuration.clone(),
    new HttpService(),
    new ApiService(),
    new NavigatorService(),
    new UrlService(),
    new MemoryStorageService(),
    new UserStorageStrategyService("userStorage", "temporaryStorage"),
    new I18nService({}, [{ iso: "fr-FR", bs: "fr" }]),
    new AclService(),
    new JwtService(),
    new MessageService(),
    new RepositoryService(ModelLoaders),
    new Networking(),
    new SessionService("pro", "bsAdminToken"),
    new CurrentTenantService()
  ]);

  application.addServiceAlias("simple-storage", "temporaryStorage");
  application.addServiceAlias("storage", "temporaryStorage");

  await beforeStart(application);
  await application.start();
  await afterStart(application);
  
  return application;
};

export default createApplication;